import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

function ModalComponent({ isActiveModal, closeModal, children, modalTitle }) {
	function closeTheModal() {
		closeModal();
	}

	return (
		<>
			<Transition appear show={isActiveModal} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeTheModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-2 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95">
								<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
										{modalTitle}
									</Dialog.Title>
									<div className="mt-2">{children}</div>

									<div className="mt-4 flex items-center justify-end">
										<button
											type="button"
											className="text-white/90 bg-[#172337] rounded-md py-2.5 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out"
											onClick={closeTheModal}>
											Close
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default ModalComponent;
