import { useState } from "react";
import { Tab } from "@headlessui/react";
import OfficeHolidayTab from "./OfficeHolidayTab";
import CommunicationTab from "./CommunicationTab";
import GeneralTab from "./GeneralTab";

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Example() {
	let [cat] = useState([
		{
			id: 1,
			tabName: "General",
			tabSection: GeneralTab,
		},
		{
			id: 2,
			tabName: "Office Holiday",
			tabSection: OfficeHolidayTab,
		},
		{
			id: 3,
			tabName: "Communications",
			tabSection: CommunicationTab,
		},
	]);

	return (
		<div className="max-w-6xl mx-auto px-2 pt-8 sm:px-0 flex flex-col gap-2 lg:flex-row">
			<Tab.Group>
				<Tab.List className="flex gap-1 flex-wrap lg:flex-col rounded-lg bg-slate-300/40 p-1">
					{cat.map((category) => (
						<Tab
							key={category.id}
							className={({ selected }) =>
								classNames(
									"w-auto rounded-md py-3 px-3 flex-grow md:flex-grow-0 text-[.9rem] font-medium leading-5",
									"ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
									selected ? "bg-white text-blue-700 shadow" : "text-[#172337] hover:bg-white/[0.5]"
								)
							}>
							{category.tabName}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels className="lg:flex-grow">
					{cat.map((eachTab, idx) => (
						<Tab.Panel
							key={idx}
							className={classNames(
								"rounded-xl bg-white min-h-[500px] p-3 p-sm-4",
								"ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
							)}>
							<ul className="p-0">{<eachTab.tabSection />}</ul>
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
}
