import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { TostMessageContext } from "../../context/TostMessage";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import clsx from "clsx";

export function OtpVerificationForm() {
  const { SendOtpForEmailVerificationFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const { setTostMessage } = useContext(TostMessageContext);
  const { email } = useParams();
  const navigate = useNavigate();
  const { VerifyOTPFunction } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  useEffect(() => {
    if (email === "") {
      setTostMessage({
        messageType: "error",
        message: "Email not found login again",
      });
      navigate("/login");
    }
  }, []);
  const onSubmit = (data) => {
    setLoading(true);
    VerifyOTPFunction({ email: email, ...data }, setLoading);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <input
        type="number"
        placeholder={"Enter code"}
        {...register("code", {
          required: true,
        })}
        className={clsx("input w-full bg-base-100 signup-input", {
          ["border border-danger"]: errors.code,
        })}
      />

      <div className="mt-10 join join-vertical lg:join-horizontal flex gap-5 justify-between">
        <div className="flex flex-col">
          <div className="font-bold lg:text-xl">
            Did not get the Code?{" "}
            <div
              className="text-primary fw-bolder cursor-pointer"
              onClick={() => SendOtpForEmailVerificationFunction({ email })}
            >
              {" "}
              Resend mail
            </div>
          </div>
        </div>
        {loading ? (
          <ReactLoading type={"cylon"} color="#322a86" />
        ) : (
          <button
            disabled={loading}
            type="submit"
            className="bg-native-blue rounded-lg text-white p-3 px-5"
          >
            Verify
          </button>
        )}
      </div>
    </form>
  );
}
