import React from 'react';

import {
  Box,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import { TostMessageContext } from '../../context/TostMessage';
import { VerificationContext } from '../../context/VerificationContext';

function Step1FormComponent() {
  const firstField = React.useRef();
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { userProfile } = React.useContext(AuthContext);
  const { verifyUserFormData, setVerifyUserFormData } =
    React.useContext(VerificationContext);
  // // console.log(userProfile);

  //  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      'image/*',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (!allowedTypes.some((type) => file.type.match(type))) {
      // alert("Please select a valid file type (image, pdf, or document)");
      setTostMessage({
        messageType: 'error',
        message: 'Please select a valid file type (image, pdf, or document)',
      });
    } else if (file.size > 5242880) {
      // 5MB in bytes
      setTostMessage({
        messageType: 'error',
        message: 'File size should be less than 5MB',
      });
    } else {
      setVerifyUserFormData({
        ...verifyUserFormData,
        [event.target.name]: file,
      });
    }
  };
  const handleChange = (event) => {
    setVerifyUserFormData({
      ...verifyUserFormData,
      [event.target.name]: event.target.value,
    });
    // // console.log(verifyUserFormData.event.target.name);
    // let tt = `${event.target.name}`;
    // // console.log(tt, verifyUserFormData);
  };

  const handelSubmits = (e) => {
    e.preventDefault();

    // // console.log(formData);
  };
  return (
    <form
      onSubmit={(e) => {
        handelSubmits(e);
      }}
      encType="multipart/form-data"
      className="row"
    >
      <div className="col-12 mt-3">
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="id_file">
            Upload valid CAC (max 3mb)
          </FormLabel>
          <input
            type="file"
            id="id_file"
            name="id_file"
            onChange={(e) => handleFileSelect(e)}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
          />
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              placeholder={
                verifyUserFormData.id_file ? 'Selected' : 'Select a file'
              }
              readOnly={true}
            />
            <InputRightElement width="4.5rem">
              <FormLabel
                style={{ fontSize: 13 }}
                className="file-select-label-button"
                // h="1.75rem"
                size={'sm'}
                htmlFor="id_file"
              >
                {verifyUserFormData.id_file ? 'Selected' : 'Upload'}
              </FormLabel>
            </InputRightElement>
          </InputGroup>
        </Box>
      </div>
      <div className="col-12 mt-3">
        {' '}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="id_number">
            Registration number
          </FormLabel>
          <Input
            ref={firstField}
            id="id_number"
            name="id_number"
            // value={formData.id_number}
            onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
      </div>

      <div className="col-md-6 mt-3">
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="country">
            Country
          </FormLabel>
          <Select
            id="country"
            defaultValue=" "
            name="country"
            onChange={(e) => handleChange(e)}
          >
            <option value=""></option>
            <option value="nigeria">Nigeria</option>
            <option value="ghana">Ghana</option>
          </Select>
        </Box>
      </div>
      <div className="col-md-6 mt-3">
        {' '}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="state">
            State
          </FormLabel>
          <Select
            id="state"
            defaultValue=" "
            name="state"
            onChange={(e) => handleChange(e)}
          >
            <option value=""></option>
            <option value="Abuja">Abuja</option>
            <option value="Lagos">Lagos</option>
          </Select>
        </Box>
      </div>

      <div className="col-12 mt-3">
        {' '}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="phone">
            Contact
          </FormLabel>
          <Input
            ref={firstField}
            id="phone"
            name="phone"
            onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
      </div>
      <div className="col-12 mt-3">
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="address">
            Address
          </FormLabel>
          <Input
            ref={firstField}
            id="address"
            name="address"
            onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
      </div>
      <div className="col-12 mt-3">
        {' '}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="utility_bill">
            Upload a recent utility bill (.pdf, .png, .jpg, .jpeg) (max 3mb)
          </FormLabel>
          <input
            type="file"
            id="utility_bill"
            name="utility_bill"
            onChange={(e) => handleFileSelect(e)}
            style={{ display: 'none' }}
            accept="image/*,application/pdf"
          />
          <InputGroup size="md" className="mb-2">
            <Input
              pr="4.5rem"
              placeholder={
                verifyUserFormData.utility_bill ? 'Selected' : 'Select a file'
              }
              readOnly={true}
            />
            <InputRightElement width="4.5rem">
              <FormLabel
                style={{ fontSize: 13 }}
                className="file-select-label-button"
                // h="1.75rem"
                size={'sm'}
                htmlFor="utility_bill"
              >
                {verifyUserFormData.utility_bill ? 'Selected' : 'Upload'}
              </FormLabel>
            </InputRightElement>
          </InputGroup>
          <sup>Utility should not be older than 3 months</sup>
        </Box>
      </div>
      <div className="col-12 mt-3">
        {' '}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="referrer">
            How did you hear about us? (Optional)
          </FormLabel>
          <Input
            ref={firstField}
            id="referrer"
            name="referrer"
            onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
      </div>

      {/* <button
        className="next-button mt-3 mb-3 mx-auto"
        type="submit"
        // disabled={loading}
      >
        {loading ? "Loading" : "Next"}
      </button> */}
      {/* <Button
        isDisabled={formLoading}
        className="next-button mt-3 mb-3 mx-auto"
        type="submit"
      >
        {formLoading ? "Loading" : "Next"}
      </Button> */}
    </form>
  );
}

export default Step1FormComponent;
